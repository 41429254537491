// ? These components function with alpine + tailwind UI components

import gsap from "gsap";

// window.obaia.Components.darkMode = () => {
//     const darkmodeSwitch = document.getElementById('js-toggle-dark')
//     return {
//         darkMode: false,
//         init() {
//             if (darkmodeSwitch) {
//                 // On page load or when changing themes, best to add inline in `head` to avoid FOUC
//                 if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
//                     this.toggleDarkMode(true)
//                     // document.documentElement.classList.add('dark')
//                 } else {
//                     this.toggleDarkMode(false)
//                     // document.documentElement.classList.remove('dark')
//                 }
//
//                 // // Whenever the user explicitly chooses light mode
//                 // localStorage.theme = 'light'
//
//                 // // Whenever the user explicitly chooses dark mode
//                 // localStorage.theme = 'dark'
//
//                 // // Whenever the user explicitly chooses to respect the OS preference
//                 // localStorage.removeItem('theme')
//
//                 darkmodeSwitch.addEventListener('change', ()=> {
//                     if (darkmodeSwitch.checked) {
//                         this.toggleDarkMode(true)
//                         // localStorage.theme = 'dark'
//                     } else {
//                         this.toggleDarkMode(false)
//                         // localStorage.theme = 'light'
//                     }
//                 }, {passive: true})
//             }
//         },
//         toggleDarkMode(status) {
//             if (status) {
//                 document.documentElement.classList.add('dark')
//                 darkmodeSwitch.checked = true
//                 this.darkMode = true
//             } else {
//                 document.documentElement.classList.remove('dark')
//                 darkmodeSwitch.checked = false
//                 this.darkMode = false
//             }
//         }
//     }
// }

window.obaia.Components.card3DEffect = () => {
    return {
        // Current transform states
        currentX: 0,
        currentY: 0,
        // Target transform states
        targetX: 0,
        targetY: 0,
        // Tweening speed
        tweenSpeed: 0.1,
        animationFrameId: null,
        boundTweenTransform: null,
        isAnimating: false,
        init() {
            this.reset(true)
            // requestAnimationFrame(this.tweenTransform.bind(this))
            this.boundTweenTransform = this.tweenTransform.bind(this)
            this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
        },
        applyTransform() {
            this.$refs.card.style.transform = `rotateY(${this.currentY}deg) rotateX(${this.currentX}deg) translateZ(-50px)`
            this.$refs.text.style.transform = `rotateY(${this.currentY}deg) rotateX(${this.currentX}deg) translateZ(0px)`
        },
        mouseMove(event) {
            const cardRect = this.$refs.card.getBoundingClientRect()
            const mouseX = event.clientX - cardRect.left
            const mouseY = event.clientY - cardRect.top

            this.targetX = ((mouseY - (cardRect.height / 2)) / cardRect.height * 30).toFixed(2)
            this.targetY = ((mouseX - (cardRect.width / 2)) / cardRect.width * 30).toFixed(2)

            if (!this.isAnimating) {
                this.startAnimation()
            }
        },
        tweenTransform() {
            if (Math.abs(this.currentX - this.targetX) > 0.01 || Math.abs(this.currentY - this.targetY) > 0.01) {
                this.currentX += (this.targetX - this.currentX) * this.tweenSpeed
                this.currentY += (this.targetY - this.currentY) * this.tweenSpeed
                this.applyTransform()
                this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
            } else {
                // When the target is reached, stop the animation
                this.isAnimating = false
            }
        },
        startAnimation() {
            if (!this.isAnimating) {
                this.isAnimating = true
                this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
            }
        },
        reset(shouldApplyTransformImmediately = false) {
            this.targetX = 0
            this.targetY = 0
            if (shouldApplyTransformImmediately) {
                this.currentX = 0
                this.currentY = 0
                this.applyTransform()
            }
        },
        mouseLeave() {
            // Smoothly transition back instead of stopping abruptly
            this.reset() // No immediate transform apply, let tween handle it
        },
        mouseEnter() {
            // Ensure animation can restart on mouse enter
            this.startAnimation()
        }
    }
}

window.obaia.Components.miniParallax = (options) => {
    // let ticking = false
    // let lastScroll = 0
    return {
        parallaxValue: '0px',
        threshold: options?.threshold ?? 4,
        direction: options?.direction ?? 'down',
        ticking: false,
        lastScroll: 0,
        status: false,
        elementTop: 0, // Store the element's initial top offset
        init() {
            // Assuming this.$el is the element you're applying the parallax effect to
            this.elementTop = this.$el.getBoundingClientRect().top + window.scrollY;
        },
        handleScroll() {
            this.lastScroll = window.scrollY;
            // Only request an animation frame if not already requested
            if (!this.ticking && this.status) {
                window.requestAnimationFrame(() => {
                    this.update.call(this)
                })
                this.ticking = true
            }
        },
        update() {
            // Update the value with the last known scroll position
            const viewportHeight = window.innerHeight
            const elementHeight = this.$el.offsetHeight

            // Determine the adjusted scroll position, taking the element's height into account
            let adjustedScroll = this.lastScroll + viewportHeight - this.elementTop

            // Ensure the adjusted scroll is between 0 and the sum of the viewport height and element height
            // This prevents the parallax effect from inverting midway through the element
            adjustedScroll = Math.max(0, Math.min(adjustedScroll, viewportHeight + elementHeight))

            // Update the parallax value based on the adjusted scroll position and the threshold
            let newParallaxValue = (this.direction === 'up' ? '-' : '') + Math.abs(Math.round(adjustedScroll / this.threshold)) + 'px'

            gsap.to(
                this.$el,
                {
                    "--parallax": newParallaxValue,
                    // ease: "slow(2, 2, false)",
                    ease: "sine.out",
                }
            )
            this.ticking = false
        }
    }
}
// window.obaia.Components.typingText = function (options = {}) {
//     return {
//         text: options.text ?? '',
//         textArray: options.textArray ?? [],
//         textIndex: options.textIndex ?? 0,
//         charIndex: options.charIndex ?? 0,
//         pauseEnd: options.pauseEnd ?? 1500,
//         cursorSpeed: options.cursorSpeed ?? 420,
//         pauseStart: options.pauseStart ?? 20,
//         typeSpeed: options.typeSpeed ?? 110,
//         direction: 'forward',
//         typingInterval: null,
//         init() {
//             const cursor = this.$refs.cursor
//             if (cursor) {
//                 setInterval(() => {
//                     if (cursor.classList.contains('hidden')) {
//                         cursor.classList.remove('hidden')
//                     } else {
//                         cursor.classList.add('hidden')
//                     }
//                 }, this.cursorSpeed);
//             }
//             this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed)
//         },
//         startTyping() {
//             let current = this.textArray[this.textIndex]
//             if (this.charIndex > current.length) {
//                 this.direction = 'backward'
//                 clearInterval(this.typingInterval)
//                 setTimeout(() => {
//                     this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed);
//                 }, this.pauseEnd);
//             }
//             this.text = current.substring(0, this.charIndex)
//             if (this.direction === 'forward') {
//                 this.charIndex += 1;
//             } else {
//                 if (this.charIndex === 0) {
//                     this.direction = 'forward'
//                     clearInterval(this.typingInterval)
//                     setTimeout(() => {
//                         this.textIndex += 1;
//                         if (this.textIndex >= this.textArray.length) {
//                             this.textIndex = 0;
//                         }
//                         this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed);
//                     }, this.pauseStart);
//                 }
//                 this.charIndex -= 1;
//             }
//         }
//     }
// }

window.obaia.Components.increment = function (option) {
    return {
        // Component state
        number: option.number,
        min: option.min ?? 0,
        max: option.max ?? null,
        startY: 0,
        endY: 0,
        dragging: false,
        direction: '',
        icon: null,
        // Component initialization
        init() {
            this.icon = this.$refs.icon
            // Bind methods to ensure 'this' context is preserved
            this.handleDrag = this.handleDrag.bind(this)
            this.stopDrag = this.stopDrag.bind(this)
            this.startDrag = this.startDrag.bind(this)
        },

        // Method to handle drag action
        handleDrag(event) {
            if (!this.dragging) {
                this.dragging = true
                this.direction = ''
            }
            this.endY = event.clientY
            const diff = this.startY - this.endY

            if (Math.abs(diff) >= 10) { // Threshold for change
                let tempNumber = this.number + (diff > 0 ? 1 : -1)
                this.number = Math.max(tempNumber, this.min)
                if (this.max !== null) {
                    this.number = Math.min(this.number, this.max)
                }
                this.startY = event.clientY // Reset start position for continuous dragging
                this.direction = diff > 0 ? 'up' : 'down' // Determine direction
                this.animateIcon() // Apply animation based on direction
            }
        },

        // Method to stop drag action
        stopDrag() {
            window.removeEventListener('mousemove', this.handleDrag)
            window.removeEventListener('mouseup', this.stopDrag)
            this.dragging = false
            this.direction = '' // Clear direction on drag stop
            this.icon.style.transform = '' // Reset icon position
            document.body.style.userSelect = ''
            this.$dispatch('calculate')
            // window.dispatchEvent(new CustomEvent('calculate'))
        },

        // Method to animate icon based on drag direction
        animateIcon() {
            if (this.direction === 'up') {
                this.icon.style.transform = 'translateY(-8px)'
            } else if (this.direction === 'down') {
                this.icon.style.transform = 'translateY(8px)'
            }
            // setTimeout(() => {
            //     this.icon.style.transform = '';
            // }, 200); // Reset transform after 200ms
        },

        // Method to start drag action
        startDrag(event) {
            this.startY = event.clientY
            window.addEventListener('mousemove', this.handleDrag)
            window.addEventListener('mouseup', this.stopDrag)
            document.body.style.userSelect = 'none'
        }
    }
}

window.obaia.Components.mouseTracker = function (e = {
    intensity: 10,
    rootElem: null,
}) {

    return {
        counter: 0,
        updateRate: 2,
        intensity: e.intensity,
        rootElem: e.rootElem,
        mouse: {
            _x: 0,
            _y: 0,
            x: 0,
            y: 0,
            updatePosition: function (e) {
                this.x = e.clientX - this._x
                this.y = (e.clientY - this._y) * -1
            },
            updatePositionFromCenterPoint: function (e, target, root) {
                target = root ?? target
                this.x = e.clientX - target.getBoundingClientRect().left - (target.getBoundingClientRect().width / 2)
                this.y = e.clientY - target.getBoundingClientRect().top - (target.getBoundingClientRect().height / 2)
            },
            setOrigin: function (e) {
                this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2)
                this._y = e.offsetTop + Math.floor(e.offsetHeight / 2)
            },
            show: function () {
                return "(" + this.x + ", " + this.y + ")"
            }
        },
        init() {
            this.mouse.setOrigin(this.$el)
        },
        leave() {
            gsap.to(
                this.$el,
                {
                    "--transform-parallax-x": 0,
                    "--transform-parallax-y": 0,
                    // ease: "slow(2, 2, false)",
                    duration: 2.5,
                    ease: "elastic.out(2, 0.4)",
                }
            )
        },
        update(e) {
            // const target = this.$el.querySelectorAll('[data-mouse-ref]')
            // // const target = this.$el
            // for (let i = 0; i < target.length; i++) {
            //     this.mouse.updatePositionFromCenterPoint(e, target[i], this.rootElem)
            //     this.updateTransformStyle(
            //         (this.mouse.x / this.intensity),
            //         (this.mouse.y / this.intensity),
            //         target[i]
            //     )
            // }
            // this.mouse.updatePositionFromCenterPoint(e, target[i], this.rootElem)
            this.mouse.updatePositionFromCenterPoint(e, this.$el, this.rootElem)
            gsap.to(
                this.$el,
                {
                    "--transform-parallax-x": (this.mouse.x / this.intensity),
                    "--transform-parallax-y": (this.mouse.y / this.intensity),
                    ease: "slow(2, 2, false)",
                }
            )
        },
        isTimeToUpdate() {
            return this.counter++ % this.updateRate === 0
        },
        move(e) {
            if (this.isTimeToUpdate()) {
                this.update(e)
            }
        },
        enter(e) {
            this.mouse.setOrigin(this.$el)
            this.update(e)
        }
    }
}

window.obaia.Components.menu = function (e = {
    open: !1
}) {
    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                this.$watch("open", (() => {
                        this.open && (this.activeIndex = -1)
                    }
                ))
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: e.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open,
            this.open && this.$nextTick((() => {
                    this.$refs["menu-items"].focus()
                }
            ))
        },
        onButtonEnter() {
            this.open = !this.open,
            this.open && (this.activeIndex = 0,
                this.activeDescendant = this.items[this.activeIndex].id,
                this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                )))
        },
        onArrowUp() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = this.items.length - 1,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onArrowDown() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = 0,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onClickAway(e) {
            if (this.open) {
                const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                this.open = !1,
                e.target.closest(t) || this.focusButton()
            }
        }
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}

window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
    return {
        value: void 0,
        active: void 0,
        init() {
            let t = Array.from(this.$el.querySelectorAll("input"));
            this.value = t[e]?.value;
            for (let e of t)
                e.addEventListener("change", (() => {
                        console.log(e.value);
                        this.active = e.value
                    }
                )),
                    e.addEventListener("focus", (() => {
                            this.active = e.value
                        }
                    ));
            window.addEventListener("focus", (() => {
                    console.log("Focus change"),
                    t.includes(document.activeElement) || (console.log("HIT"), this.active = void 0
                    )
                }
            ), !0)
        }
    }
}